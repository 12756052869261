import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faEnvelope,
  faHome,
  faPhoneAlt,
  faBell,
  faSpinner,
  faAt,
  faClock,
  faEdit,
  faUser,
  faKey,
  faPeopleCarry,
  faBullhorn,
  faCalendarCheck,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faSignOutAlt,
  faUserFriends,
  faSignInAlt,
  faHandshake,
  faInfo,
  faAddressBook,
  faCalendarAlt,
  faUsers,
  faUndo,
  faTimes,
  faExclamationTriangle,
  faBriefcase,
  faCaretLeft,
  faCaretRight,
  faMobile,
  faMapMarkerAlt,
  faGlobe,
  faMapPin,
  faArrowAltCircleUp,
  faUmbrella,
  faUserCog,
  faEye,
  faBuilding,
  faChurch,
  faPrayingHands,
  faBible,
  faSchool,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons"

export const icons = [
  faEnvelope,
  faHome,
  faPhoneAlt,
  faBell,
  faSpinner,
  faAt,
  faClock,
  faEdit,
  faUser,
  faKey,
  faPeopleCarry,
  faBullhorn,
  faCalendarCheck,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faSignOutAlt,
  faUserFriends,
  faSignInAlt,
  faHandshake,
  faInfo,
  faAddressBook,
  faCalendarAlt,
  faUsers,
  faUndo,
  faTimes,
  faExclamationTriangle,
  faBriefcase,
  faCaretLeft,
  faCaretRight,
  faMobile,
  faMapMarkerAlt,
  faGlobe,
  faMapPin,
  faArrowAltCircleUp,
  faUmbrella,
  faUserCog,
  faEye,
  faBuilding,
  faChurch,
  faPrayingHands,
  faBible,
  faSchool,
  faHandsHelping,
]
